import { FACIAL_SPOT_LOCATIONS, GENDER } from '@/modules/questionnaire/api/constants';

import FaceTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/face/FaceTemplate';

export default {
  title: 'Steps/SkinPigmentation/Face/FaceTemplate',
  component: FaceTemplate
};

const createStory = props => () => ({
  components: { FaceTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><face-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  facialSpotLocations: [FACIAL_SPOT_LOCATIONS.FOREHEAD, FACIAL_SPOT_LOCATIONS.CHIN],
  gender: GENDER.FEMALE
});
